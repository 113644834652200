import React, { useState } from 'react';
import { SEMESTERS, PROFESSORS, ACADEMIC_YEARS } from '../utils/constants';

function StatisticsForm({ handleFetchStats }) {
  const [filterSemester, setFilterSemester] = useState("");
  const [filterProfessor, setFilterProfessor] = useState("");
  const [filterAcademicYear, setFilterAcademicYear] = useState(ACADEMIC_YEARS[0]);

  const isFilterValid = () => filterSemester && filterProfessor && filterAcademicYear;

  const onSubmit = (e) => {
    e.preventDefault();
    if (isFilterValid()) {
      handleFetchStats({ filterSemester, filterProfessor, filterAcademicYear });
    }
  };

  return (
    <section className="bg-gray-50 rounded-2xl p-8 shadow-lg">
      <h2 className="text-3xl sm:text-4xl font-bold mb-6 text-gray-800">Fetch Attendance Statistics</h2>
      <form className="space-y-6" onSubmit={onSubmit}>
        <select
          value={filterSemester}
          onChange={(e) => setFilterSemester(e.target.value)}
          className="w-full p-4 border border-gray-300 rounded-lg"
        >
          <option value="">Select Semester</option>
          {SEMESTERS.map((sem) => (
            <option key={sem} value={sem}>Semester {sem}</option>
          ))}
        </select>
        <select
          value={filterProfessor}
          onChange={(e) => setFilterProfessor(e.target.value)}
          className="w-full p-4 border border-gray-300 rounded-lg"
        >
          <option value="">Select Professor</option>
          {PROFESSORS.map((prof) => (
            <option key={prof} value={prof}>{prof}</option>
          ))}
        </select>
        <select
          value={filterAcademicYear}
          onChange={(e) => setFilterAcademicYear(e.target.value)}
          className="w-full p-4 border border-gray-300 rounded-lg"
        >
          {ACADEMIC_YEARS.map((year) => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>
        <button
          type="submit"
          disabled={!isFilterValid()}
          className="w-full mt-6 bg-gradient-to-r from-green-500 to-blue-500 text-white p-4 rounded-lg hover:from-green-600 hover:to-blue-600 transition duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed text-lg font-semibold"
        >
          Fetch Statistics
        </button>
      </form>
    </section>
  );
}

export default StatisticsForm;