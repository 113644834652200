import { useState } from 'react';
import { db } from '../firebase';
import { collection, getDocs, query } from 'firebase/firestore';
import { notifySuccess, notifyError } from '../utils/notifications';

function useStatistics() {
  const [filteredStatistics, setFilteredStatistics] = useState([]);
  const [showStatistics, setShowStatistics] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);

  const handleFetchStats = async ({ filterSemester, filterProfessor, filterAcademicYear }) => {
    try {
      const attendanceQuery = query(
        collection(db, filterAcademicYear, filterProfessor, `Semester ${filterSemester}`)
      );
      const querySnapshot = await getDocs(attendanceQuery);
      const filteredAttendanceArray = querySnapshot.docs.map((doc) => doc.data());

      if (filteredAttendanceArray.length === 0) {
        notifyError("No data available for the selected filters.");
        return;
      }

      const rollNumberMap = filteredAttendanceArray.reduce((acc, { rollNumber }) => {
        if (!acc[rollNumber]) acc[rollNumber] = 0;
        acc[rollNumber] += 1;
        return acc;
      }, {});

      const aggregatedData = Object.entries(rollNumberMap).map(([rollNumber, count]) => ({
        rollNumber,
        attendedLectures: count,
      }));

      setFilteredStatistics(aggregatedData);
      setShowStatistics(true);
      setDataFetched(true);
      notifySuccess("Statistics fetched successfully.");
    } catch (error) {
      console.error("Error fetching filtered statistics: ", error);
      notifyError("Error fetching statistics.");
    }
  };

  return { filteredStatistics, showStatistics, handleFetchStats, dataFetched };
}

export default useStatistics;