import jsPDF from "jspdf";
import "jspdf-autotable";
import { notifySuccess, notifyError } from "./notifications";

export const generatePDF = (filteredStatistics, attendance) => {
  try {
    const doc = new jsPDF();

    const professorInfo = filteredStatistics.length > 0 ? filteredStatistics[0].professor : "";
    const semesterInfo = filteredStatistics.length > 0 ? filteredStatistics[0].semester : "";
    const academicYearInfo = filteredStatistics.length > 0 ? filteredStatistics[0].academicYear : "";
    const totalLectures = calculateTotalLectures(filteredStatistics.length ? filteredStatistics : attendance);

    const title =
      `Attendance Report\n\n` +
      `Academic Year: ${academicYearInfo}\n` +
      `Semester: ${semesterInfo}\n` +
      `${professorInfo}\n\n` +
      `Total Lectures: ${totalLectures}`;

    doc.setFontSize(16);
    const splitTitle = doc.splitTextToSize(title, 180);
    doc.text(splitTitle, 14, 20);

    const headers = ["Roll Number", "Attended Lectures", "Attendance %"];
    const rows = (filteredStatistics.length ? filteredStatistics : attendance).map(({ rollNumber, attendedLectures }) => [
      rollNumber,
      attendedLectures,
      ((attendedLectures / totalLectures) * 100).toFixed(2) + "%",
    ]);

    doc.autoTable({
      startY: 70,
      head: [headers],
      body: rows,
      margin: { top: 10 },
      styles: { fontSize: 12 },
    });

    const currentDate = new Date().toISOString().split("T")[0];
    const fileName = `attendance_report_${currentDate}_SEM_${semesterInfo}_${professorInfo}.pdf`;

    doc.save(fileName);
    notifySuccess("Data downloaded as PDF file successfully.");
  } catch (error) {
    console.error("Error downloading PDF file: ", error);
    notifyError("Error downloading PDF file.");
  }
};

const calculateTotalLectures = (data) => {
  if (data.length === 0) return 0;
  return Math.max(...data.map((item) => item.attendedLectures));
};