import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

function LoginForm({ handleLogin }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();
    if (recaptchaToken) {
      handleLogin(email, password);
    } else {
      alert("Please verify that you are not a robot.");
    }
  };

  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  return (
    <section className="bg-gray-50 rounded-2xl p-8 shadow-lg">
      <h2 className="text-3xl sm:text-4xl font-bold mb-6 text-gray-800">Login</h2>
      <form onSubmit={onSubmit} className="space-y-6">
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
            required
          />
        </div>
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700">Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
            required
          />
        </div>
        <ReCAPTCHA
          sitekey="6LdgbTAqAAAAANrHylxssZEKe3cUoZMwOlYQBc_M"
          onChange={onRecaptchaChange}
        />
        <button
          type="submit"
          className="w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white p-4 rounded-lg hover:from-blue-600 hover:to-purple-600 transition duration-300 ease-in-out text-lg font-semibold"
          disabled={!recaptchaToken}
        >
          Login
        </button>
      </form>
    </section>
  );
}

export default LoginForm;
