import React from 'react';
import { generatePDF } from '../utils/pdfGenerator';

function DownloadPDFButton({ dataFetched, filteredStatistics, attendance }) {
  const handleDownloadPdf = () => {
    generatePDF(filteredStatistics, attendance);
  };

  return (
    <button
      onClick={handleDownloadPdf}
      disabled={!dataFetched}
      className="w-full sm:w-auto bg-gradient-to-r from-red-500 to-red-700 text-white px-8 py-4 rounded-lg hover:from-red-600 hover:to-red-800 transition duration-300 ease-in-out flex items-center justify-center text-lg font-semibold"
    >
      <svg className="w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
      </svg>
      Download PDF Report
    </button>
  );
}

export default DownloadPDFButton;