export const SEMESTERS = [1, 2, 3, 4, 5, 6, 7, 8];

export const PROFESSORS = [
  "Prof. Dr. Rashmi Verma",
  "Prof. Dr. Nitesh Solanki",
  "Prof. Dr. Pooja Shukla",
  "Prof. Dr. Anurag Puvar"
];

export const ACADEMIC_YEARS = [
  "2024-25",
  "2025-26",
  "2026-27",
  "2027-28"
];