import React from 'react';

function StatisticsTable({ filteredStatistics }) {
  return (
    <section className="bg-white rounded-2xl p-8 shadow-lg overflow-x-auto">
      <h2 className="text-3xl sm:text-4xl font-bold mb-6 text-gray-800">Statistics</h2>
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-4 rounded-tl-lg">Roll Number</th>
            <th scope="col" className="px-6 py-4 rounded-tr-lg">Attended Lectures</th>
          </tr>
        </thead>
        <tbody>
          {filteredStatistics.map((stat, index) => (
            <tr key={stat.rollNumber} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} hover:bg-gray-100 transition duration-200`}>
              <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">{stat.rollNumber}</td>
              <td className="px-6 py-4">{stat.attendedLectures}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}

export default StatisticsTable;