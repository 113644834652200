import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

import LoginForm from "./components/LoginForm";
import AttendanceForm from "./components/AttendanceForm";
import StatisticsForm from "./components/StatisticsForm";
import StatisticsTable from "./components/StatisticsTable";
import DownloadPDFButton from "./components/DownloadPDFButton";
import LoadingScreen from "./components/LoadingScreen"; // Import the LoadingScreen component

import useAuth from "./hooks/useAuth";
import useAttendance from "./hooks/useAttendance";
import useStatistics from "./hooks/useStatistics";

function App() {
  const [loading, setLoading] = useState(true); // State to manage loading screen

  useEffect(() => {
    // Show loading screen for 5 seconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const { user, handleLogin, handleLogout } = useAuth();
  const { attendance, handleAddAttendance } = useAttendance();
  const { 
    filteredStatistics, 
    showStatistics, 
    handleFetchStats, 
    dataFetched 
  } = useStatistics();

  return (
    <>
      {loading ? (
        <LoadingScreen /> // Show loading screen if loading state is true
      ) : (
        <div className="min-h-screen bg-gradient-to-br from-blue-100 via-purple-100 to-pink-100 flex flex-col items-center p-4 sm:p-8">
          <ToastContainer position="top-center" autoClose={3000} />
          <div className="w-full max-w-full bg-white rounded-3xl shadow-2xl p-4 sm:p-8 md:p-12 space-y-10">
            <header className="text-center">
              <h1 className="text-5xl sm:text-7xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600 mb-4">
                CUSAC
              </h1>
              <p className="text-3xl sm:text-4xl font-semibold text-gray-700">
                Attendance Portal
              </p>
            </header>

            {!user ? (
              <LoginForm handleLogin={handleLogin} />
            ) : (
              <>
                <button onClick={handleLogout} className="bg-red-500 text-white p-2 rounded-lg hover:bg-red-600 transition duration-300 ease-in-out">
                  Logout
                </button>

                <AttendanceForm handleAddAttendance={handleAddAttendance} />
                <StatisticsForm handleFetchStats={handleFetchStats} />

                {showStatistics && (
                  <StatisticsTable filteredStatistics={filteredStatistics} />
                )}

                <DownloadPDFButton 
                  dataFetched={dataFetched} 
                  filteredStatistics={filteredStatistics} 
                  attendance={attendance}
                />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default App;
