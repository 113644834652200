import { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { notifySuccess, notifyError } from '../utils/notifications';

function useAuth() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  const handleLogin = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      notifySuccess("Logged in successfully");
    } catch (error) {
      notifyError("Login failed: " + error.message);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      notifySuccess("Logged out successfully");
    } catch (error) {
      notifyError("Logout failed: " + error.message);
    }
  };

  return { user, handleLogin, handleLogout };
}

export default useAuth;