import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getAuth } from 'firebase/auth';

// ... (existing Firebase app initialization)



const firebaseConfig = {
  apiKey: "AIzaSyB_3uIooJs5QOqRBWp9BdNAVB8ON4ftD-k",
  authDomain: "cusacattendance.firebaseapp.com",
  projectId: "cusacattendance",
  storageBucket: "cusacattendance.appspot.com",
  messagingSenderId: "746492449637",
  appId: "1:746492449637:web:64a958e905cdade61a878c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore and Functions
const db = getFirestore(app);
const functions = getFunctions(app);

export { db, functions };
export const auth = getAuth(app);
