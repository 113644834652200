import React, { useState, useEffect } from 'react';
import logo from './logo512.png'; // Adjust the import path as needed

const LoadingScreen = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          return 100;
        }
        const newProgress = oldProgress + 1;
        return Math.min(newProgress, 100);
      });
    }, 50);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gradient-to-br from-purple-900 via-indigo-900 to-blue-900">
      <img src={logo} alt="Logo" className="w-32 h-32 mb-8 animate-pulse" />
      <div className="w-64 h-4 bg-gray-700 rounded-full overflow-hidden">
        <div 
          className="h-full bg-gradient-to-r from-cyan-400 via-blue-500 to-purple-600 transition-all duration-500 ease-out"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <p className="mt-4 text-white text-xl font-light tracking-wider">
        Loading... {progress}%
      </p>
      <div className="mt-8 text-blue-300 text-sm font-medium">
        Please Wait Server Is Processing Your Request...
      </div>
    </div>
  );
}

export default LoadingScreen;