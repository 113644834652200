import { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { notifySuccess, notifyError } from '../utils/notifications';

function useAttendance() {
  const [attendance, setAttendance] = useState([]);

  const handleAddAttendance = async ({ rollNumber, date, semester, professor, academicYear }) => {
    try {
      const attendanceQuery = query(
        collection(db, academicYear, professor, `Semester ${semester}`),
        where("rollNumber", "==", rollNumber),
        where("date", "==", date)
      );
      const querySnapshot = await getDocs(attendanceQuery);

      if (querySnapshot.empty) {
        await addDoc(
          collection(db, academicYear, professor, `Semester ${semester}`),
          { rollNumber, date }
        );
        fetchAttendance({ semester, professor, academicYear });
        notifySuccess("Attendance submitted successfully.");
      } else {
        notifyError("Attendance for this roll number has already been submitted for today.");
      }
    } catch (error) {
      console.error("Error adding attendance data: ", error);
      notifyError("Error submitting attendance.");
    }
  };

  const fetchAttendance = async ({ semester, professor, academicYear }) => {
    try {
      const attendanceQuery = query(
        collection(db, academicYear, professor, `Semester ${semester}`)
      );
      const querySnapshot = await getDocs(attendanceQuery);
      const attendanceArray = querySnapshot.docs.map((doc) => doc.data());
      setAttendance(attendanceArray);
    } catch (error) {
      console.error("Error fetching attendance data: ", error);
      notifyError("Error fetching attendance data.");
    }
  };

  return { attendance, handleAddAttendance };
}

export default useAttendance;